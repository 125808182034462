import React from 'react'
import { HiArrowNarrowRight } from 'react-icons/hi'

const Home = () => {
  return (
    <div name='home' className='w-full h-screen bg-[#0a192f]'>

        {/*Container*/}
        <div className='max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full'>
            <p className='text-pink-600'>Hello, my name is</p>
            <h1 className='text-4xl sm:text-7xl font-bold text-[#ccd6f6]'>Michael Navarro</h1>
            <h2 className='text-4xl sm:text-7xl font-bold text-[#8892b0]'>I'm a Full Stack Developer.</h2>
            <p className='text-[#8892b0] py-4 max-w-[700px]'>I'm a full-stack developer specializing 
                in Machine Learning, and
                Artificial Intelligence design and architecture. I can also build full-stack 
                responsive web applications and mobile applications. In my spare time I enjoy
                hunting bug bounties, using platforms such as HackerOne and BugCrowd. Some of the 
                penetration testing tools I continue to master, but not limited to are,
                Metasploit, Nmap, BurpSuite, and WireShark. I have a deep understanding of Network
                protocols and the OSI model. I look forward to working with you on projects! </p>
            <div>
                <button className='text-white group border-2 px-6 py-3 my-2 flex item-center hover:bg-pink-600 hover:border-pink-600'>View Work 
                <span className='group-hover:rotate-90 duration-300'>
                <HiArrowNarrowRight className='ml-3'/>
                </span>
                </button>
            </div>
        </div>

    </div>
  )
}

export default Home